<template>
  <div class="login-main">
    <b-row>
      <b-col md="5" lg="4" sm="12">
        <div class="modal-login">
          <div class="container-login-img"><LogoEmpresa /></div>
          <transition mode="out-in" name="fade-left" appear>
            <!-- Login -->
            <div class="container-login" v-if="!esqueceuSenha" key="login">
              <div v-if="!esqueceuSenha">
                <p>Acessar</p>
                <div class="container-login-inputs">
                  <inputValidate :erro="emailLoginErro">
                    <input
                      type="text"
                      placeholder="E-mail"
                      v-model.trim="login.email"
                      @blur="$v.login.email.$touch()"
                      @keyup.enter="enterParaLogin"
                    />
                  </inputValidate>
                  <inputValidate :erro="senhaLoginErro">
                    <input
                      type="password"
                      placeholder="Senha"
                      v-model.trim="login.password"
                      @blur="$v.login.password.$touch()"
                      @keyup.enter="enterParaLogin"
                    />
                  </inputValidate>
                </div>
                <div class="container-login-botoes">
                  <b-button block variant="primary" class="btn-enviar-login" @click="fazerLogin" :disabled="$v.login.$invalid">
                    Entrar
                  </b-button>
                </div>
              </div>
              <div class="login-links">
                <a class="esqueceu-senha" @click="subscribe">Não tenho uma conta</a>
                <a class="esqueceu-senha" @click="toggleView">Esqueci minha senha</a>
              </div>
            </div>
            <!-- Esqueceu Senha -->
            <div class="container-senha" v-else key="senha">
              <p>Recuperação de senha</p>
              <inputValidate :erro="emailEsqueciErro">
                <input
                  type="text"
                  placeholder="e-mail"
                  v-model.trim="emailEsqueci"
                  @blur="$v.emailEsqueci.$touch()"
                  @keyup.enter="enterParaSenha"
                />
              </inputValidate>
              <b-button
                block
                variant="primary"
                class="btn-enviar-login"
                @click="esqueciSenha"
                :disabled="$v.emailEsqueci.$invalid"
              >
                Enviar
              </b-button>
              <div class="login-links">
                <a class="senha-voltar" @click.prevent="toggleView">Voltar</a>
              </div>
            </div>
          </transition>
        </div>
      </b-col>
      <b-col md="7" lg="8" class="colored_side"></b-col>
    </b-row>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { emailLoginErro, senhaLoginErro, emailEsqueciErro } from "@/plugins/vuelidate/login";
import * as api from "@/api/login";
import inputValidate from "@/components/formulario/InputValidate.vue";
import LogoEmpresa from "@/components/logos/LogoPositivo.vue";
export default {
  name: "login",
  components: {
    inputValidate,
    LogoEmpresa,
  },
  data() {
    return {
      esqueceuSenha: false,
      login: {
        email: "",
        password: "",
      },
      emailEsqueci: "",
    };
  },
  validations: {
    login: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
    emailEsqueci: {
      required,
      email,
    },
  },
  computed: {
    emailLoginErro,
    senhaLoginErro,
    emailEsqueciErro,
  },
  methods: {
    subscribe() {
      this.$router.push("/subscribe");
    },
    toggleView() {
      this.esqueceuSenha = !this.esqueceuSenha;
    },
    enterParaLogin() {
      this.$v.login.$invalid ? false : this.fazerLogin();
    },
    fazerLogin() {
      api.enviarLogin(this.login);
    },
    esqueciSenha() {
      api.recuperarSenha(this.emailEsqueci);
    },
    enterParaSenha() {
      this.$v.emailEsqueci.$invalid ? false : this.esqueciSenha();
    },
  },
};
</script>

<style lang="scss" scoped>
.login-main {
  min-height: 100vh;
  background-color: #ffffff;
  padding: 0;
}
.colored_side {
  @include gradientRight;
  min-height: 100%;
  display: none;
  @include for-tablet-landscape-down {
    display: block;
  }
}
.modal-login {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 18px;
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
  @include for-tablet-landscape-down {
    padding: 0 50px;
  }
}
.login-links {
  padding: 24px;
  text-align: center;

  a {
    display: block;
    cursor: pointer;
    text-decoration: underline;
    color: $textos-login;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 400;
  }
}
.container-login-img {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 35%;
  img {
    width: 100%;
    max-width: 450px;
    height: auto;
    max-height: 250px;
    pointer-events: none;
    @media (max-width: 700px) {
      max-height: 150px;
    }
  }
}
.container-login,
.container-senha {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 250px;
  > div {
    width: 100%;
  }
  p {
    font-size: 22px;
    width: 100%;
    margin: 20px 0;
    color: $textos-login;
  }
}
.btn-enviar-login {
  background: $red;
  border-color: $red;
  font-weight: 500;
  padding: 10px;
  &:hover {
    background: darken($red, 5%);
    border-color: darken($red, 5%);
  }
  &:disabled,
  &:focus {
    background: $red;
    border-color: $red;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem lighten($red, 20%);
  }
}
.login-copy {
  width: 100%;
  text-align: center;
  p {
    font-size: 18px;
    color: $textos-login;
  }
}
</style>
